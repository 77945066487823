export const mapPortfolio = (portfolio) => (Object.assign(Object.assign({}, portfolio), { 
    // don`t know would it be blob or url ?
    logo: '', 
    // chat_activity: portfolio.portfoliocommentSet?.length,
    chat_activity: 0, 
    // copiers_count: portfolio.copyingSet?.length || 100,
    copiers_count: 0, 
    // viewed: portfolio.portfolioviewSet?.length,
    viewed: 0, 
    // parent_exchange_id:
    //     portfolio.exchangeConnectionPortfolio?.length ?
    //         portfolio.exchangeConnectionPortfolio[0].id : '0',
    referral_asset: '', 
    // TODO add field after readiness on backend
    favorited_count: 0, shared_count: 0, min_balance: 0, graph_data: [], min_balance_currency: '$', minimum_balance_type: 'fifth_part', overall_difference: 0, past_week_difference: 20.55, past_month_difference: 15.81, past_ninety_days_difference: 18.71, whole_period_days_count: 150, traders_balance: 250, traders_balance_currency: '$', subscriptionPriceCurrency: '$', past_month_start_date: '2023-11-15T13:09:56.276376+00:00', past_month_end_date: '2023-10-15T14:09:56.276376+00:00', followers: {
        count: 0,
        // count: Number(portfolio.profile?.followersCount),
        average_followers_monthly_return: 0,
        // average_followers_monthly_return: 15.22,
        average_followers_monthly_return_first: 0,
        // average_followers_monthly_return_first: 16.00,
        average_followers_monthly_return_last: 0,
        // average_followers_monthly_return_last: 15.00,
        preview_followers: [],
    }, type: 'Spot', user_subscription_summary: {
        queue_position: 1,
        duration: 14,
        past_month_difference: 10,
        unrealized_pl_value: 14.63,
        unrealized_pl_percentange: 4.8,
        unrealized_pl_currency: '$',
        unrealized_pl_trend: 'up',
        realized_pl_value: 1424.23,
        realized_pl_currency: '$',
        realized_pl_trend: 'up',
        realized_pl_percentange: 5.63,
        subscription_date: new Date('December 17, 2023'),
    }, visibility: 'public', is_archived: false, has_error: false }));
const mapPortfoliosList = (list) => list === null || list === void 0 ? void 0 : list.map(mapPortfolio);
export default mapPortfoliosList;
