import { PLATFORM_BALANCE, REALTIME_SUMMARY_BALANCE, USER_ACTIVITY_MOCK } from './mocks';
const getProfileActivityHistory = () => {
    const activity = [...USER_ACTIVITY_MOCK].sort((a, b) => {
        if (a.timestamp < b.timestamp) {
            return 1;
        }
        return -1;
    });
    const groupedActivity = {};
    activity.forEach((activityEvent) => {
        const eventDate = new Date(activityEvent.timestamp);
        const dateKey = `${eventDate.getFullYear()}-${eventDate.getMonth()}-${eventDate.getDate()}`;
        if (typeof groupedActivity[dateKey] === 'undefined') {
            groupedActivity[dateKey] = {
                date: eventDate,
                positions: [activityEvent],
            };
        }
        else {
            groupedActivity[dateKey].positions.push(activityEvent);
        }
    });
    return groupedActivity;
};
const getPlatformBalance = () => PLATFORM_BALANCE;
const getRealtimeSummaryBalance = () => REALTIME_SUMMARY_BALANCE;
export { getProfileActivityHistory, getPlatformBalance, getRealtimeSummaryBalance, };
