var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import restApiAuthorized from '@app/store/api/restApiAuthorized';
import { getCopiersQueueDetails, getCurrentPositions, getPositionsHistory, } from './mocks';
import mapPortfoliosList, { mapPortfolio } from './utils';
const portfoliosApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        createPortfolio: builder.mutation({
            query: ({ portfolioName, profileId, subscriptionPrice, walletId, }) => ({
                url: '/api/v1/portfolio',
                body: {
                    portfolioName,
                    profileId,
                    subscriptionPrice,
                    walletId,
                },
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        updatePortfolio: builder.mutation({
            query: (_a) => {
                var { portfolioId } = _a, optionalParams = __rest(_a, ["portfolioId"]);
                return ({
                    url: `/api/v1/portfolio/${portfolioId}`,
                    body: Object.assign({}, optionalParams),
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        deletePortfolio: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/portfolio/${id}`,
                method: 'DELETE',
            }),
        }),
        getPopularList: builder.query({
            query: () => ({
                url: '',
                body: {},
                method: 'GET',
                transformResponse: mapPortfoliosList,
            }),
            providesTags: ['PopularPortfolios'],
            transformResponse: (data) => mapPortfoliosList(data),
        }),
        getPortfolio: builder.query({
            query: ({ portfolioId }) => ({
                url: `/api/v1/portfolio/${portfolioId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { portfolioId }) => [{ type: 'Portfolios', id: portfolioId }],
            // eslint-disable-next-line max-len
            transformResponse: mapPortfolio,
        }),
        getCurrentUserProfilePortfolios: builder
            .query({
            query: ({ profileId }) => ({
                url: `/api/v1/profile/${profileId}/portfolio`,
                method: 'GET',
            }),
            providesTags: (result) => (result ?
                [...result.map(p => ({ type: 'CurrentUserPortfolios', id: p.id })), 'CurrentUserPortfolios'] :
                ['CurrentUserPortfolios']),
            transformResponse: mapPortfoliosList,
        }),
        getPortfolioCopiers: builder.query({
            query: ({ portfolioId, skip, take }) => ({
                url: '',
                body: {},
                method: 'GET',
            }),
            providesTags: (result) => (result ?
                [
                    ...result.map(c => ({ type: 'PortfolioCopiers', id: c.id })),
                    'PortfolioCopiers',
                ] :
                ['PortfolioCopiers']),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByDelay }) => [],
        }),
        getPortfolioCopiersQueueDetails: builder
            .query({
            query: ({ portfolioId }) => ({
                url: '',
                body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            transformResponse: () => getCopiersQueueDetails(),
        }),
        startCopying: builder.mutation({
            query: ({ portfolioId }) => ({
                url: '',
                body: {},
                method: 'POST',
            }),
            invalidatesTags: ['PortfolioCopiers'],
        }),
        getPortfolioCurrentPositions: builder
            .query({
            query: ({ portfolioId, skip, take, sortByPnl, }) => ({
                url: '',
                body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByPnl }) => getCurrentPositions(skip, take, sortByPnl),
        }),
        getPortfolioPositionsHistory: builder
            .query({
            query: ({ portfolioId, skip, take, }) => ({
                url: '',
                body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take }) => getPositionsHistory(skip, take),
        }),
    }),
});
export default portfoliosApi;
export const { useCreatePortfolioMutation, useGetPopularListQuery, useLazyGetPopularListQuery, useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, useStartCopyingMutation, useGetPortfolioCurrentPositionsQuery, useGetPortfolioPositionsHistoryQuery, useGetPortfolioQuery, useLazyGetPortfolioQuery, useDeletePortfolioMutation, useUpdatePortfolioMutation, useGetCurrentUserProfilePortfoliosQuery, useLazyGetCurrentUserProfilePortfoliosQuery, } = portfoliosApi;
