import { IsDefined } from '@utils/js-ts';
import { numberToFixed2 } from '@shared/lib/numbers';
import CURRENT_POSITIONS from './current_positions.json';
import PROFILES from './profiles';
const getCurrentPositions = (skip, take, sortByPnl = 'asc') => {
    let currentPositions = CURRENT_POSITIONS;
    if (sortByPnl === 'asc') {
        currentPositions = currentPositions.sort((a, b) => a.pnl - b.pnl);
    }
    else if (sortByPnl === 'desc') {
        currentPositions = currentPositions.sort((a, b) => b.pnl - a.pnl);
    }
    if (IsDefined(skip) && IsDefined(take)) {
        currentPositions = currentPositions.slice(Number(skip), Number(skip) + Number(take));
    }
    return {
        count: CURRENT_POSITIONS.length,
        data: currentPositions,
    };
};
const getPositionsHistory = (skip, take) => {
    let history = CURRENT_POSITIONS;
    const months = {};
    history = history.sort((a, b) => new Date(a.opened).getTime() - new Date(b.opened).getTime());
    history.forEach(position => {
        try {
            const positionsMonth = new Date(position.opened).getMonth();
            if (!IsDefined(months[positionsMonth])) {
                months[positionsMonth] = {
                    month: positionsMonth,
                    count: 1,
                    positions: [position],
                };
            }
            else {
                months[positionsMonth].count += 1;
                months[positionsMonth].positions.push(position);
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    });
    const result = {};
    if (IsDefined(skip) && IsDefined(take)) {
        history = history.slice(Number(skip), Number(skip) + Number(take));
        history.forEach(position => {
            try {
                const positionsMonth = new Date(position.opened).getMonth();
                if (!IsDefined(result[positionsMonth])) {
                    result[positionsMonth] = {
                        month: positionsMonth,
                        count: months[positionsMonth].count,
                        positions: [position],
                    };
                }
                else {
                    result[positionsMonth].positions.push(position);
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        });
    }
    return {
        count: CURRENT_POSITIONS.length,
        data: result,
    };
};
function getRandomNumberWithTwoDecimals(min, max) {
    const random = Math.random() * (max - min) + min;
    return parseFloat(random.toFixed(2));
}
const getCopiersData = () => {
    const copiers = [];
    for (let i = 0; i < PROFILES.length; i += 1) {
        const profileData = PROFILES[i];
        const username = i % 3 === 0 ? '' : profileData.username;
        const nickname = i % 3 === 0 ? '' : profileData.nickname;
        const avatar = (i % 2 === 0) || (i % 3 === 0) ? '' : '/avatars/Avatar.png';
        const profile = {
            id: i + 1,
            avatar,
            username,
            nickname,
            user_id: 1,
            type: 'CLIENT',
            location: profileData.location,
            joined_since: profileData.joined_since,
            description: profileData.description,
        };
        const portfolioSubscription = {
            id: i + 1,
            profile_id: profile.id,
            profile,
            queue_position: i + 1,
            past_month_difference: getRandomNumberWithTwoDecimals(-150.00, 150.00),
            amount: 830,
            bid: 15 + (PROFILES.length - 1 - i) * 5,
            position: i + 1,
            stop_loss: 15,
            delay: 0.01 * (i + 1),
            projected_income: 90 - (i * 0.3),
            manual_intervention: (i % 5 === 0),
        };
        copiers.push(portfolioSubscription);
    }
    return copiers;
};
export const getCopiersQueueDetails = () => {
    const copiers = getCopiersData();
    const first = copiers[0];
    const last = copiers[copiers.length - 1];
    const response = {
        first,
        last,
        copiers_count: copiers.length,
        delay_step: 0.01,
        avg_price_per_position: '',
        projected_income: {
            total: 0,
            step: 0,
        },
    };
    if (IsDefined(first)) {
        response.avg_price_per_position = numberToFixed2(first.bid / copiers.length);
    }
    if (IsDefined(first) && IsDefined(last)) {
        response.projected_income = {
            total: 90.0,
            step: 0.3,
        };
    }
    return response;
};
export { getCurrentPositions, getPositionsHistory, };
